<template>
  <v-row class="pb-5" v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Resumen del proveedor" :dense="true" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.suppliers.retrieveLoader, text: instance.name }" :hiddenTab="true" :addPadding="true" :scroll="scroll"
    >
      <template v-slot:main>
      <v-btn color="blue-500" @click="dialogSupplier = true" :disabled="contactsLoading"><v-icon left>mdi-pencil</v-icon>Editar cliente</v-btn>
    </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="$store.state.purchases.retrieveLoader" />
      <div class="d-flex" v-else>
        <PermissionDenied v-if="!$helpers.hasPermission(['view_supplier'])" />
        <template v-else>
          <!-- detail card -->
          <div class="mr-6" style="min-width: 400px;">
            <div class="mt-n1" style="position:fixed!important;">
              <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-supplier.svg`)">
                <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 300px;">
                  {{instance.name}}
                </span>
                <v-row class="ml-2 mt-3" align="end" no-gutters>
                  <v-col cols="5" class="py-0 px-2">
                    <span class="d-block body-1 white--text">RUT</span>
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold">{{instance.tax_id}}</span>
                    </span>
                  </v-col>
                </v-row>
              </v-img>
              <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
                <v-toolbar color="transparent" flat height="46">
                  <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">Resumen del proveedor</v-toolbar-title>
                </v-toolbar>
                <v-divider />

                <v-card-text class="py-4 px-5">
                  <v-list class="transparent pa-0">
                    <v-list-item class="pa-0" v-if="instance.tax_settings">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-hand-coin</v-icon>Actividad económica</v-list-item-title>
                      <v-list-item-subtitle class="body-1" v-if="instance.tax_settings.cl_activity_description">{{instance.tax_settings.cl_activity_description}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-currency-usd</v-icon>Moneda</v-list-item-title>
                      <v-list-item-subtitle class="body-1" v-if="instance.currency">{{instance.currency}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-map-marker</v-icon>Dirección</v-list-item-title>
                      <v-list-item-subtitle class="body-1" v-if="instance.address">{{instance.address}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-map-marker</v-icon>Comuna</v-list-item-title>
                      <v-list-item-subtitle class="body-1" v-if="instance.state">{{instance.state}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-card-bulleted</v-icon>ID</v-list-item-title>
                      <v-list-item-subtitle class="body-1">
                        <v-tooltip color="white-0" right transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-btn class="grey-300--text" @click="toClipboard(instance.id)" text v-on="on" :ripple="false" style="justify-content: left;">{{instance.id}}</v-btn>
                          </template>
                          <span class="d-block px-3 py-2">Copiar</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-calendar-blank</v-icon>Creado el</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.created | dateTime}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="d-flex align-center"><v-icon size="16" left color="grey-500">mdi-calendar-blank</v-icon>Actualizado el</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.updated | dateTime}}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <!-- end summary -->

          <!-- panels -->
          <div style="flex:1; min-width: 200px;">
            <!-- contacts -->
            <v-expansion-panels v-model="contactPanel">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-5 py-4">
                  <v-row no-gutters align="center">
                    <span class="ml-n1 subtitle-2 grey-500--text font-weight-bold"><v-icon class="mr-2" small color="grey-500">mdi-account</v-icon>Contactos</span>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <v-skeleton-loader class="rounded-md" v-if="contactsLoading" transition="fade-transition" type="table-tbody" />
                  <template v-else>
                    <v-empty-state class="mt-5 pb-2" v-if="!contactsList.length" type="customer-econ" id="documentos" customClass="mt-5" :isFree="true" title="Aún no tienes contactos" :height="139" />
                    <v-simple-table class="table-not-rounded no-hover pt-0 pb-1 px-2 transparent" v-else dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left grey-500--text font-weight-medium" style="min-width: 180px;"><v-icon class="ml-n2" small left color="grey-500">mdi-account</v-icon>Nombre</th>
                            <th class="text-left grey-500--text font-weight-medium" style="min-width: 180px;"><v-icon small left color="grey-500">mdi-email</v-icon>Email</th>
                            <th style="min-width: 50px;" class="text-right grey-500--text font-weight-medium"><v-icon small left color="grey-500">mdi-phone</v-icon>Teléfono</th>
                            <th style="min-width: 60px;"/>
                          </tr>
                          <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -8px" />
                        </thead>
                        <tbody>
                          <tr v-for="contact in contactsList" :key="contact.id">
                            <td class="body-2">
                              <span class="d-inline-block text-truncate ml-n1" v-if="contact.name" style="max-width: 100%;">{{contact.name}}</span>
                              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                            </td>
                            <td class="text-left body-2" style="min-width: 180px;">
                              <span class="d-inline-block text-truncate" style="max-width: 100%;">{{contact.email}}</span>
                            </td>
                            <td class="text-right body-2">
                              <span v-if="contact.phone">{{contact.phone}}</span>
                              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                            </td>
                            <td class="text-right body-2">
                              <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="grey-300--text" size="24">mdi-dots-vertical</v-icon></v-btn>
                                </template>
                                <v-list class="pa-0">
                                  <v-list-item class="px-3" @click="destroy(contact.id)" :ripple="false">
                                    <v-list-item-title class="body-2"><v-icon color="grey-500" size="20" left>mdi-delete</v-icon>Eliminar contacto</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end contacts -->

            <!-- related documents -->
            <v-card class="mt-5" flat>
              <v-card-text class="pa-0">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="px-5 py-4">
                    <span class="subtitle-2 grey-500--text font-weight-bold"><v-icon size="18" left color="grey-500">mdi-file-document-multiple</v-icon>Documentos relacionados</span>
                  </v-col>
                </v-row>
                <v-divider />
                <purchase-list :loading="loading" :show-pagination="false" />
                <template v-if="count">
                  <v-divider />
                  <v-row class="px-4 py-4" align="center">
                    <v-btn class="ml-5 body-1" :to="{name: 'PurchaseList', query: { supplier: instance.id, ...(instance.name && { name: instance.name })} }" :ripple="false" text color="blue-500">Ver todos los documentos</v-btn>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
            <!-- end related documents -->
          </div>
          <!-- end panels -->
        </template>
      </div>
    </v-col>

     <!--modal update supplier-->
    <v-dialog v-model="dialogSupplier" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Editar cliente</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogSupplier=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
          <v-row class="px-5" no-gutters>
            <v-col cols="3" class="pa-0">
              <span class="grey-700--text body-1">{{$t('generals.RUT')}}</span>
              <v-text-field v-model="editable.tax_id" readonly disabled v-dni="$store?.state?.auth?.account?.country" outlined single-line dense hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">Nombre del proveedor</span>
              <v-text-field v-model.trim="$v.editable.name.$model" :error="$v.editable.name.$error" outlined single-line dense maxlength="100" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 py-3" no-gutters>
            <v-col cols="12" class="pa-0">
              <span class="grey-700--text body-1">Actividad económica</span>
              <v-text-field v-model="editable.tax_settings.cl_activity_description" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 pb-0" no-gutters>
            <v-col cols="8" class="pa-0">
              <span class="grey-700--text body-1">Dirección</span>
              <v-text-field v-model.trim="$v.editable.address.$model" :error="$v.editable.address.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">{{$t('generals.Comuna')}}</span>
              <v-autocomplete v-model="$v.editable.state.$model" :error="$v.editable.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 grey-700--text font-weight-semibold">
              Contactos
              <v-chip class="ml-2 pa-2 font-weight-regular" x-small color="grey-background">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in contacts" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-4 pa-0">
                  <span class="grey-700--text body-1">Nombre</span>
                  <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1">
                  <span class="grey-700--text body-1">Email</span>
                  <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-3 pa-0" style="max-width: 290px;">
                  <span class="grey-700--text body-1">Teléfono</span>
                  <v-text-field v-model="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                </div>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-5" v-if="i >= 1 || contacts.length > 1" @click="destroy(d.id), contacts.splice(i, 1)" text color="grey-500" :ripple="false"><v-icon color="blue-500">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn class="mt-1" v-if="contacts.length < 7" @click.prevent="addRowContacts" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otro</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSupplier=false">Cancelar</v-btn>
          <v-btn color="blue-500" :loading="$store.state.suppliers.createLoader" @click="updateSupplier">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal update supplier -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import MiniHeader from '@/components/commons/MiniHeader'
  import cloneDeep from 'lodash/cloneDeep'
  import { required, email, maxLength } from 'vuelidate/lib/validators'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import VEmptyState from '@/components/commons/VEmptyState'
  import RenderPdf from '@/components/files/RenderPdf'
  import PurchaseList from '@/modules/purchase/components/PurchaseList'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'
  // import DocumentTypes from '@/collections/documentTypes'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      ChipStatus,
      VModal,
      SkeletonDetail,
      RenderPdf,
      PurchaseList,
      MiniHeader,
      PermissionDenied,
      VEmptyState
    },
  data: () => ({
    contactPanel: 0,
    dialogSupplier: false,
    contactsLoading: false,
    scroll: 0,
    comments: '',
    dialogSwap: false,
    loading: false,
    panelIrsSii: [],
    panelComments: false,
    panelEmail: false,
    panelPDF: false,
    panelRelatedDocuments: 0,
    isAccept: false,
    dialogConfirm: false,
    currentList: [],
    current: {},
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Proveedores',
          route: 'SupplierListCreate'
        }
      ]
    },
    contacts: [{ name: '', email: '', phone: '' }],
    editable: {
      name: '',
      tax_id: '',
      tax_settings: {
        cl_activity_description: ''
      },
      state: '',
      address: ''
    }
  }),
  computed: mapState({
    statesList: state => state.suppliers.statesList,
    count: state => state.purchases.purchasesCount,
    purchasesList: state => state.purchases.purchasesList,
    instance: state => state.suppliers.suppliersDetail,
    contactsList: state => state.suppliers.contactsList
  }),
  created () {
    this.$store.dispatch('suppliers/RETRIEVE', {
      resource: 'suppliers',
      id: this.$route.params.id
    })
    .then(() => {
      const supplier = cloneDeep(this.instance)
      this.editable = supplier
      this.getContacts()
    })
    this.$store.dispatch('purchases/LIST', {
      resource: 'purchases',
      query: {
        supplier: this.$route.params.id,
        page_size: 6
      }
    })
  },
  methods: {
    destroy (id = '') {
      this.$store.dispatch('suppliers/DESTROY', {
        resource: `suppliers/contacts`,
        id: id
      })
      .then(() => {
        this.getContacts()

        this.$dialog.message.info('El contacto ha sido eliminado')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data.detail))
      })
      .finally(() => {
        this.dialogDelete = false
        this.confirm = ''
      })
    },
    addRowContacts () {
      this.contacts.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getContacts () {
      this.contactsLoading = true
        this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/contacts',
          query: {
            supplier: this.$route.params.id
        }
      })
      .then((response) => {
        if (response.data.results.length) {
          this.contacts = cloneDeep(response.data.results)
        }
      })
      .finally(() => {
        this.contactsLoading = false
      })
    },
    updateSupplier () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
      const payload = {
        name: this.editable.name,
        state: this.editable.state.id || this.editable.state,
        address: this.editable.address,
        tax_id: this.editable.tax_id,
        tax_settings: this.editable.tax_settingss,
        country: this.$store?.state?.auth?.account?.country
      }
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.$route.params.id,
        payload: payload
      })
      .then(async (response) => {
        await Promise.all(this.contacts.map(async (item) => {
        if (item?.id) {
          this.$store.dispatch('suppliers/UPDATE', {
            resource: 'suppliers/contacts',
            id: item.id,
            payload: {...item, ...{ supplier: response.data.id }}
          })
        } else if (item.email.length || item.name.length || item.phone.length) {
          this.$store.dispatch('suppliers/CREATE', {
            resource: 'suppliers/contacts',
            payload: {...item, ...{ supplier: response.data.id }}
          })
        }
      }))
        this.$dialog.message.info('El proveedor ha sido actualizado')
      })
      .catch(() => {
        this.$dialog.message.error('Error al actualizar el proveedor.')
      })
      .finally(() => {
        this.dialogSupplier = false
      })
    }
  },
  validations: {
    editable: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      state: {
        required
      },
      address: {
        required,
        maxLength: maxLength(64)
      }
    },
    contacts: {
      $each: {
        email: {
        required,
          email,
          maxLength: maxLength(64)
        }
      }
    }
  }
}
</script>